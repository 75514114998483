@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  width: 100%;
  height: 100%;
}

@layer base {
  html, body {
    font-family: "Poppins", Roboto, sans-serif;
  }
}